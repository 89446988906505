<template>
  <div id="form">
    <!-- Form -->
    <!-- :disabled="isUserCreated" -->
    <el-form
      :ref="formName"
      :model="query"
      label-width="100px"
      label-position="right"
    >
      <!-- 用户帐号 -->
      <el-row :gutter="20">
        <el-col :span="11">
          <el-form-item
            label="用户帐号"
            prop="username"
          >
            <el-input
              v-model="query.username"
              :disabled="true"
            />
          </el-form-item>
        </el-col>
        <el-col
          :span="11"
          :offset="2"
        >
          <!-- 会员类别 -->
          <el-form-item
            label="会员类别"
            prop="category"
          >
            <el-select
              v-model="query.category"
              style="width:100%;"
            >
              <el-option
                v-for="(option, idx) in categoryOption"
                :key="'option-' + idx"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="11">
          <!-- 用户昵称 -->
          <el-form-item
            label="昵称"
            prop="nickname"
          >
            <el-input
              v-model="query.nickname"
              :disabled="isUserCreated"
            />
          </el-form-item>
        </el-col>
        <el-col
          :span="11"
          :offset="2"
        >
          <!-- 等级 -->
          <el-form-item
            label="等级"
            prop="level"
          >
            <el-input-number
              :disabled="true"
              v-model="query.level"
              :min="1"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="11">
          <!-- 邮箱地址 -->
          <el-form-item
            label="邮箱"
            prop="email"
          >
            <el-input
              v-model="query.email"
              :disabled="isUserCreated"
            />
          </el-form-item>
        </el-col>
        <el-col
          :span="11"
          :offset="2"
        >
          <!-- 手机 -->
          <el-form-item
            label="手机"
            prop="mobile"
          >
            <el-select
              v-model="query.countrycode"
              placeholder="国码"
              :disabled="isUserCreated"
              style="width:100%;"
            >
              <el-option
                v-for="(option, idx) in countryCode"
                :key="'country-' + idx"
                :label="getCountryCodeLabel(option)"
                :value="option.code"
              />
            </el-select>
            <el-input
              v-model="query.mobile"
              placeholder="手机号码"
              :disabled="isUserCreated"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="11">
          <!-- QQ -->
          <el-form-item
            label="QQ"
            prop="qq"
          >
            <el-input
              v-model="query.qq"
              :disabled="isUserCreated"
            />
          </el-form-item>
        </el-col>
        <el-col
          :span="11"
          :offset="2"
        >
          <!-- 密码 -->
          <el-form-item label="密码">
            <el-button @click="handleEditPass">
              修改密码
            </el-button>
            <!-- <edit-pass
              :dialogData="dialogData"
              :passVisible="passVisible"
              @handlePassVisible="handlePassVisible" /> -->
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- Footer -->
    <div
      slot="footer"
      class="dialog-footer"
    >
      <!-- 取消 -->
      <el-button @click="handleCancel">
        取消
      </el-button>
      <!-- 确认 -->
      <el-button
        type="primary"
        @click="handleSubmit"
      >
        确认
      </el-button>
    </div>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { categoryOption } from '@/constants/member'
// import EditPass from './EditPass'
import { isMemberEditable, adminLogout } from '@/utils/admin'
import { getUser, postEditUser, postEditPass } from '@/api/admin/member'
import { arrCountryCode } from '@/constants/countryCode'

export default {
  // components: {
  //   EditPass
  // },
  data () {
    return {
      formName: 'editForm',
      query: {
        id: '',
        username: ''
      },
      passVisible: false,
      categoryOption: categoryOption,
      countryCode: arrCountryCode
    }
  },
  computed: {
    isUserCreated: {
      get () {
        return isMemberEditable(this.query)
      }
    }
  },
  methods: {
    init () {
      getUser(this.query.id).then(res => {
        switch (res.status) {
          case resStatus.OK:
            this.query = res.data
            break
          default:
            adminLogout()
            this.$message.error(res.message)
        }
      }).catch(err => {
        adminLogout()
        this.$message.error(err)
      })
    },
    getCountryCodeLabel (option) {
      const arrShow = [option.code, option.country]
      const basicSpaces = '\u00a0'
      const charSpaces = '\u00a0\u00a0'
      let joinSpaces = `${basicSpaces}`

      for (let i = option.code.length, maxCodeLen = 7; i < maxCodeLen; i++) {
        joinSpaces = `${joinSpaces}${charSpaces}`
      }
      return arrShow.join(joinSpaces)
    },
    handleEditPass () {
      // this.passVisible = !this.passVisible
      if (
        this.query.countrycode.length > 0 &&
        this.query.mobile.length > 0
      ) {
        this.$confirm(
          '将发送一组新的密码到绑定手机，请到绑定手机号中收取，并尽快至多彩网登录，登录后请到完善资料进行修改密码。',
          '修改密码',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消'
          }
        )
          .then(() => {
            postEditPass({
              username: this.query.username
            })
              .then(res => {
                switch (res.status) {
                  case resStatus.OK:
                    this.$message('已发送密码')
                    break
                  default:
                    this.$message.error(res.message)
                }
              })
              .catch(err => {
                this.$message.error(err)
              })
          })
          .catch(() => {})
      } else {
        this.$confirm(
          '该用户无绑定手机，无法进行修改密码。',
          '修改密码',
          {
            confirmButtonText: '确认',
            showCancelButton: false
          }
        )
          .then(() => {})
      }
    },
    handlePassVisible (boo) {
      this.passVisible = boo
    },
    handleCancel () {
      // this.$refs[this.formName].resetFields()
      // this.dialogVisible = false
      this.$router.go(-1)
    },
    handleSubmit () {
      // 'User' -> readonly, others -> editable
      // if (isMemberEditable(this.dialogData)) {
      //   this.handleCancel()
      //   return
      // }
      // validate & really edit
      this.$refs[this.formName].validate(valid => {
        if (valid) { this.editUser() }
      })
    },
    editUser () {
      postEditUser(this.query, this.query.id)
        .then(res => {
          switch (res.status) {
            case resStatus.OK:
              this.$emit('handleRefresh')
              this.handleCancel()
              break
          }
          if (res.message.length !== 0) {
            this.$message(res.message)
          }
        })
        .catch(err => {
          this.$message.error(err)
        })
    }
  },
  created () {
    this.query.id = this.$route.params.id
    this.init()
  }
}
</script>

<style lang="scss" scoped>
#form {
  max-width: calc((100vw - 200px) * 0.8);
  .el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 20;
    }
  }
  .el-input-number {
    width: 100%;
  }
  .el-form-item {
    .el-button {
      width: 100%;
    }
  }
}
</style>
