// reference: https://countrycode.org/

export const objCountryCode = {
  China: '+86',
  Taiwan: '+886'
}

export const arrCountryCode = Object.keys(objCountryCode).map(key => {
  return {
    country: key,
    code: objCountryCode[key]
  }
})
